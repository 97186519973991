import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {UserStoreService} from "@hp-core/services/user-store.service";

export const httpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const userStore = inject(UserStoreService);
  const router = inject(Router);

  if (req.url.includes('/api')) {
    if (req.url.includes('auth/login')) {
      console.info('Does not need interceptor');
      return next(req);
    }

    return userStore.getToken().pipe(
      switchMap((token) => {
        if (!userStore.isTokenExpired(token ?? '')) {
          const authRequest = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next(authRequest);
        } else {
          console.warn('Auth session expired');
          router.navigate(['auth', 'login']);
          return of(); // Devuelve un observable vacío para finalizar el flujo
        }
      })
    )
  } else {
    return next(req);
  }
};
