import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {UserStoreService} from '@hp-core/services/user-store.service';
import {toasterUtil} from '@hp-core/utils/toaster.util';
import {combineLatestWith, map} from "rxjs";

export const haveEnabledClientFeaturesGuard: CanActivateFn = (
  route,
  state,
) => {
  const userStore = inject(UserStoreService);
  const router = inject(Router);

  const isAuthRoute = state.url.startsWith('/auth');
  const hasPermission$ = userStore.observableRoleHasPermission;
  const isTokenExpired$ = userStore.observableTokenExpired;

  return isTokenExpired$.pipe(
    combineLatestWith(hasPermission$),
    map(([isTokenExpired, hasPermission]): boolean => {
      if(isTokenExpired && isAuthRoute){
        toasterUtil('Token expired');
        userStore.redirectionUrl = state.url;
        router.navigate(['auth', 'login']);
        return false;
      }

      if(!isTokenExpired && !hasPermission){
        userStore.redirectionUrl = state.url;
        router.navigate(['auth', 'login']);
        return false;
      }

      return (!isTokenExpired && hasPermission)
    })
  )
};
