
import {Component, inject, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {HlmButtonDirective} from '@spartan-ng/ui-button-helm';
import {HlmIconComponent} from "@spartan-ng/ui-icon-helm";
import {lucideLoader2} from "@ng-icons/lucide";
import {provideIcons} from "@ng-icons/core";
import {TranslateService, TranslateStore} from "@ngx-translate/core";
import {I18nModule} from "./_core/i18n/i18n.module";
import { HomeLayoutComponent } from '@hp-home/_components/home-layout/home-layout.component';
import { HlmToasterComponent } from '@spartan-ng/ui-sonner-helm';
import 'animate.css';
import {UserStoreService} from "@hp-core/services/user-store.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HlmButtonDirective,
    HlmIconComponent,
    I18nModule,
    HomeLayoutComponent,
    HlmToasterComponent
  ],
  providers: [
    provideIcons({ lucideLoader2 }),
    TranslateService,
    TranslateStore,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], // Asegúrate de usar 'styleUrls' en lugar de 'styleUrl'
})
export class AppComponent implements OnInit {
  title: string = 'Hola mundo';
  private readonly _userStore = inject(UserStoreService);
  private readonly router = inject(Router);

  constructor(private translate: TranslateService) {
    // Configura el idioma predeterminado
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  ngOnInit(): void {
    this._userStore.getToken().subscribe(token => {
      if(this._userStore.isTokenExpired(token ?? '')){
        this.router.navigate(['auth', 'login']);
      }
    })
  }
}
