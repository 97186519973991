import {Routes} from '@angular/router';
import {haveEnabledClientFeaturesGuard} from './features/auth-auth/_guards/have-enabled-client-features.guard';
import {HomeLayoutComponent} from "@hp-home/_components/home-layout/home-layout.component";

export const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./features/auth-auth/auth-auth.module').then(
            (m) => m.AuthAuthModule,
          ),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'home',
        loadChildren: () =>
          import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'bi',
        loadChildren: () =>
          import('./features/business-intelligence/business-intelligence.module').then((m) => m.BusinessIntelligenceModule),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'checkout',
        loadChildren: () =>
          import('./features/checkout-counter/checkout-counter.module').then(
            (m) => m.CheckoutCounterModule,
          ),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'movements',
        loadChildren: () =>
          import('./features/movements/movements.module').then(
            (m) => m.MovementsModule,
          ),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'settlements',
        loadChildren: () =>
          import('./features/settlements/settlements.module').then(
            (m) => m.SettlementsModule,
          ),
      },
      {
        canActivate: [haveEnabledClientFeaturesGuard],
        path: 'payment-report',
        loadChildren: () =>
          import('./features/payment-report/payment-report.module').then(
            (m) => m.PaymentReportModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'home',
      }
    ]
  }

];
